<template>
  <div
    ref="popover"
    :class="['position-relative', { 'bc-popover-visible': show }, customClass]"
  >
    <slot :show-popover="showPopover" />

    <div :class="['bc-popover', currentPosition, customContentClass]">
      <slot name="body" :close="close" />
    </div>
    <div
      v-if="show"
      :class="[
        'bc-popover__backdrop',
        { 'bc-popover__backdrop--visible': withBackdrop }
      ]"
      @click="onBackdropClick"
    />
  </div>
</template>

<script>
export default {
  props: {
    // supported: [bottom, bottomleft, bottomright, top, topleft, topright, left, right]
    position: {
      type: String,
      default: 'bottom'
    },
    mobilePosition: {
      type: String,
      default: undefined
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    mobilePositionBreakpoint: {
      type: Number,
      default: 992
    },
    customClass: {
      type: String,
      default: ''
    },
    customContentClass: {
      type: String,
      default: ''
    },
    withBackdrop: {
      type: Boolean,
      default: true
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: this.isVisible,
      mobileView: false
    }
  },
  computed: {
    currentPosition() {
      return this.mobileView && this.mobilePosition
        ? this.mobilePosition
        : this.position
    }
  },
  watch: {
    isVisible(newValue) {
      if (newValue !== this.show) {
        this.show = newValue

        if (newValue) {
          this.$nextTick(this.scrollIntoView)
        }
      }
    }
  },
  mounted() {
    this.onResize()

    if (this.isVisible) {
      this.scrollIntoView()
    }

    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    showPopover() {
      this.show = true
    },
    close() {
      this.show = false
    },
    onResize() {
      this.mobileView = window.innerWidth < this.mobilePositionBreakpoint
    },
    scrollIntoView() {
      try {
        const elementRect = this.$refs.popover.getBoundingClientRect()
        const absoluteElementTop = elementRect.top + window.pageYOffset
        const middle = absoluteElementTop - window.innerHeight / 2
        let scrollPosition = middle - 100

        if (this.currentPosition.includes('bottom')) {
          scrollPosition = absoluteElementTop - 100
        }

        setTimeout(() => window.scrollTo(0, scrollPosition), 0)
        // eslint-disable-next-line no-empty
      } catch {}
    },
    onBackdropClick() {
      if (this.closeOnBackdropClick) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bc-popover {
  display: none;
  width: 96vw;
  max-width: max-content;
  background-color: $white;
  text-align: center;
  padding: 24px;
  border-radius: 4px;
  position: absolute;
  z-index: $popover-zindex;
  will-change: filter;
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.1));
  opacity: 0;
  transition: opacity 1s;

  &::after {
    content: ' ';
    position: absolute;
    border-style: solid;
    border-width: 10px;
  }
}

.bc-popover-visible {
  .bc-popover {
    display: block;
    opacity: 1;
  }
}

.bottom,
.bottomleft,
.bottomright {
  top: calc(1rem + 100%);

  &::after {
    bottom: 100%;
    border-color: transparent transparent $white transparent;
  }
}

.bottom {
  left: 50%;
  transform: translateX(-50%);

  &::after {
    left: 47.5%;
  }
}

.bottomleft {
  left: -8px;

  &::after {
    left: 5%;
  }
}

.bottomright {
  right: 0;

  &::after {
    left: 80%;
  }
}

.top,
.topleft,
.topright {
  bottom: calc(1rem + 100%);

  &::after {
    top: 100%;
    border-color: $white transparent transparent transparent;
  }
}

.top {
  right: 50%;
  transform: translateX(50%);

  &::after {
    left: 47.5%;
  }
}

.topleft {
  left: -8px;

  &::after {
    left: 5%;
  }
}

.topright {
  right: 8px;

  &::after {
    right: 5%;
  }
}

.left {
  right: calc(1rem + 100%);
  bottom: 50%;
  transform: translateY(50%);

  &::after {
    top: 47.5%;
    left: 100%;
    border-style: solid;
    border-color: transparent transparent transparent $white;
  }
}

.right {
  left: calc(1rem + 100%);
  bottom: 50%;
  transform: translateY(50%);

  &::after {
    top: 47.5%;
    right: 100%;
    border-style: solid;
    border-color: transparent $white transparent transparent;
  }
}

.bc-popover__backdrop {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;
  width: 100%;
  height: 100%;

  &--visible {
    background: radial-gradient(
      96.72% 66.26% at 50% 50%,
      rgba(20, 24, 26, 0.5) 0%,
      rgba(20, 24, 26, 0.8) 100%
    );
  }
}

@include media-breakpoint-up(md) {
  .bc-popover {
    width: 488px;
  }

  .bottomleft,
  .topleft {
    left: 0;
  }
}
</style>
